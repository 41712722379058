import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
        <div className='max-w-[1280px] m-auto xs:mx-4 sm:mx-4 md:mx-4 lg:mx-4 xl:mx-4 xs:mt-60  sm:mt-60 md:mt-60'>
            <div className='text-center text-3xl font-bold my-8 text-[#213555]'>سياسة الخصوصية</div>
            <div className='text-right'>
                <div className='text-[17px] leading-loose mb-8'>
                خصوصيتك أيها الزائر لها أهمية بالغة بالنسبة لنا ، و سياسة الخصوصية الموجودة في هذه الوثيقة تمثل الخطوط العريضة لأنواع المعلومات الشخصية التي يجمعها موقع 
                <span> شير في الخير </span>
                وكيفية استخدام هذه المعلومات. 
                <br />
                نحن نستعين بشركات إعلان كطرف ثالث لعرض الإعلانات ، وعندما تزور موقع
                <span> شير في الخير </span>
                معلومات حول زياراتك لهذا الموقع ( باستثناء الاسم أو العنوان أو عنوان البريد الإلكتروني أو رقم الهاتف ) وذلك من أجل تقديم إعلانات حول البضائع والخدمات التي تهمك عن طريق
                <span> . ملف تعريف الإرتباط </span>
                </div>


                <span className='font-bold text-lg text-[#213555] mb-4'>: ملفات الدخول</span>
                <div className='text-[17px] leading-loose mb-8'>شأنها في ذلك شأن معظم خوادم المواقع الأخرى ، ومن هنا فإن موقع شير في الخير   يستخدم نظام ملفات الدخول ، وهذا يشمل بروتوكول الانترنت (عناوين ، نوع المتصفح ، مزود خدمة الانترنت "مقدمي خدمات الانترنت" ، التاريخ / الوقت ، وعدد النقرات لتحليل الاتجاهات).
                      من خلال هذه العملية لا يقصد بذلك جمع كل هذه المعلومات في سبيل التلصص على أمور الزوار الشخصية ، وإنما هي أمور تحليلية لأغراض تحسين جودة الإعلانات من قبل  <span>جوجل</span> ، ويضاف إلى ذلك أن جميع هذه المعلومات المحفوظة من قبلنا سرية تماما، وتبقى ضمن نطاق التطوير والتحسين الخاص بموقعنا فقط.</div>
                
                
                <span className='font-bold text-lg text-[#213555] mb-4'> : الكوكيز وإعدادات الشبكة</span>
                <div className='text-[17px] leading-loose mb-8'>إن شركة Google تستخدم تقنية الكوكيز لتخزين المعلومات عن إهتمامات الزوار، إلى جانب سجل خاص للمستخدم تسجل فيه معلومات محددة عن الصفحات التي تم الوصول إليها أو زيارتها، وبهذه الخطوة فإننا نعرف مدى اهتمامات الزوار وأي المواضيع الأكثر تفضيلا من قبلهم حتى نستطيع بدورنا تطوير محتوانا الخدمي والمعرفي المناسب لهم.
                      نضيف إلى ذلك أن بعض الشركات التي تعلن في شير في الخير - شير في الخير قد تتطلع على الكوكيز وإعدادات الشبكة الخاصة بموقعنا وبكم ، ومن هذه الشركات مثلاً شركة جوجل وبرنامجها الإعلاني جوجل ادسينس وهي شركة الإعلانات الأولى في موقعنا.
                      وبالطبع فمثل هذه الشركات المعلنة والتي تعتبر الطرف الثالث في سياسة الخصوصية فهي تتابع مثل هذه البيانات والإحصائيات عبر بروتوكولات الانترنت لأغراض تحسين جودة إعلاناتها وقياس مدى فعاليتها.
                      كما أن هذه الشركات بموجب الاتفاقيات المبرمة معنا يحق لها استخدام وسائل تقنية مثل ( الكوكيز ، و إعدادات الشبكة ، و أكواد برمجية خاصة "جافا سكربت" ) لنفس الأغراض المذكورة أعلاه والتي تتلخص في تطوير المحتوى الإعلاني لهذه الشركات وقياس مدى فاعلية هذه الإعلانات ، من دون أي أهداف أخرى قد تضر بشكل أو بآخر على زوار موقعنا.
                      وبالطبع فإن موقع شير في الخير   لا يستطيع الوصول أو السيطرة على هذه الملفات، وحتى بعد سماحك وتفعيلك لأخذها من جهازك (الكوكيز) ، كما أننا غير مسؤولين بأي شكل من الأشكال عن الاستخدام غير الشرعي لها إن حصل ذلك .
                      عليك مراجعة سياسة الخصوصية الخاصة بالطرف الثالث في هذه الوثيقة (الشركات المعلنة مثل جوجل ادسينس) أو خوادم الشبكات الإعلانية لمزيد من المعلومات عن ممارساتها وأنشطتها المختلفة.
                      لمراجعة سياسة الخصوصية للبرنامج الإعلاني جوجل ادسينس والتابع لشركة جوجل يرجى النقر هنا.
                      يُمكن جمع البيانات لتجديد النشاط التسويقي على الشبكة الإعلانية وشبكة البحث عبر سياسة الإعلانات التي تستهدف الاهتمامات والمواقع الخاصة بالزوار ، ويمكن تعطيل هذه الخاصية من هنا.
                      وأخيرا .. نحن ملزمون ضمن بنود هذه الاتفاقية بان نبين لك كيفية تعطيل خاصية الكوكيز ، حيث يمكنك فعل ذلك من خلال خيارات المتصفح الخاص بك، أو من خلال متابعة
                      . سياسة الخصوصية الخاصة بإعلانات جوجل وشبكة المحتوى
                      </div>
  
                <div className='text-[17px] leading-loose mb-8'>إذا كنت بحاجة إلى مزيد من المعلومات أو لديك أية أسئله عن سياسة الخصوصية ، لا تتردد في الاتصال بنا عن طريق نموذج الإتصال ، بالدخول إلى تبويب اتصل بنا.
                      تم تحرير هذه الصفحة بتاريخ 07/07/2023.
                      بنود هذه السياسة قابلة للتطوير والتغيير في محتواها في أي وقت نراه ضرورياً
                </div>
                </div>



        </div>
    </div>
  )
}

export default PrivacyPolicy
